import React from "react";
import Logo from "../../assets/logo1.png";

const LogoSmall = () => {
  return (
    <div>
      <img src={Logo} alt="Logo" style={{ width: "50px" }} />
    </div>
  );
};

export default LogoSmall;
