import React from "react";
import WebLink from "../atoms/WebLink";

const Menu = () => {
  return (
    <ul className="hidden md:flex">
      <WebLink label="Home" to="/" />
      <WebLink label="About" to="/about" />
      <WebLink label="Experience" to="/experience" />
      <WebLink label="Contact" to="/contact" />
    </ul>
  );
};

export default Menu;
