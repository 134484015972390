import React from "react";

const ExperienceItem = (props) => {
  return (
    <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4 mb-8">
      <div className="sm:text-right text-4xl font-bold">
        <p>{props.jobTitle} </p>
        <p>{props.company} </p>
        <p className="text-sm font-extralight">
          {props.startDate} -&gt; {props.endDate}
        </p>
      </div>
      <div>{props.description}</div>
    </div>
  );
};

export default ExperienceItem;
