import React from "react";
import { Link } from "react-router-dom";

const MobileLink = (props) => {
  return (
    <li className="py-6 text-4xl" onClick={props.onClick}>
      <Link to={props.to}>{props.label}</Link>
    </li>
  );
};

export default MobileLink;
