import React from "react";
import ExperienceItem from "../moleculs/ExperienceItem";

const Experience = () => {
  return (
    <div className="w-full min-h-screen bg-[#0a192f] text-gray-300">
      <div className="pt-[100px] flex flex-col justify-center items-center w-full h-full">
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pl-4">
            <p className="text-4xl font-bold inline border-b-4 border-[#ff5757]">
              Experience
            </p>
          </div>
          <div></div>
        </div>
        <ExperienceItem
          jobTitle="Full stack developer"
          company="Wingly"
          startDate="dec 2020"
          endDate="Today"
          description="My role involve :
                Software design, Features refinements 
                - Laravel 8/9, php 7.4/8.0/8.1
                - Laravel package development 
                - Laravel NOVA 
                - Nuxt js, Vue js, VueX
                - tools : Jira, bitbucket, Sentry, Mangopay, valet, docker, git, tower...
                - code reviews
                - devOps."
        />
        <ExperienceItem
          jobTitle="Full stack developer"
          company="Yummy Publishing GmbH"
          startDate="January, 2020"
          endDate="November, 2020"
          description="Yummy publishing GmbH is a digital company offering a SaaS service with very high traffic.
                My role involve:
                Software design, Features refinements
                - backend technologies : php 7.2, Laravel 6
                - high quality software in term of design and code.
                - Rest APIs
                - tools : atlasian, postman, docker, git
                - code reviews
                - Agile (Scrum)"
        />
        <ExperienceItem
          jobTitle="Software dev engineer"
          company="BLUM | Innoschool"
          startDate="September, 2019"
          endDate="Decempber, 2019"
          description="My role involve : 
                All the life-cycle of software development (Android app, embedded systems and web app). 
                - Prototyping and sketching 
                - Design thinking.
                - Service design.
                - From user journey to system requirements."
        />
        <ExperienceItem
          jobTitle="University Teacher"
          company="University of Tlemcen"
          startDate="september, 2019"
          endDate="july, 2019"
          description="advanced web development : 
                - Full stack technologies.
                - Object oriented design
                - Think as an architect and build the best web architecture.
                - Use the right technologies for each project.
                - How to secure a web app.
                
                2) Embedded systems : 
                -C langage.
                -Prototyped a lot (smart elevator, smart parking, smart home...etc).
                -Take in consideration the limited ressources and develop efficient algorithms.
                -Think outside the box to innovate smart."
        />
        <ExperienceItem
          jobTitle="Software engineer"
          company="Arab innovation academy"
          startDate="December, 2017"
          endDate="January, 2018"
          description="With Qatar foundation, Europe innovation academy, MIT.
          Creation of a new search engine dedicated for the academic materials.
          My role as a software engineer :
          -design thinking
          -mapping problems with solutions
          -mapping solution with technical requirements and user journeys.
          -design the MVP and the landing page.
          -build the MVP."
        />

        <ExperienceItem
          jobTitle="Software engineer"
          company="FreeLance"
          startDate="December, 2017"
          endDate="January, 2018"
          description="My role as a software engineer 
          starts From customer's needs to the best needs fit solution. includes: 
          - Requirements engineering.
          - Drafting specifications.
          - Agile working.
          - Respect the delays of the customers.
          - Web technologies and mobile app technologies.
          - Design databases and oriented object software.
          - Map the need with an easy to use solution.
          - Coding and testing (PHP Laravel 4/5, php 5/7, php unit, composer, git )."
        />

        <ExperienceItem
          jobTitle="Full-stack developer"
          company="FreeLance"
          startDate="December, 2015"
          endDate="December, 2016"
          description="My role as a full stack developer :
          - html5, css3, respensive désign
          - jscript, nodejs, angularJs
          - php(php 5/7(Object oriented)), Laravel, CakePhp, Symfony , databases(mysql)
          - web security app.
          - Natives mobile apps & hybrid with cordova and Xamarin.
          - C#, java, objectif C, XAML, Visual studio, xml, php, javascript, json.
          - wordpress
          - object oriented design"
        />
      </div>
    </div>
  );
};

export default Experience;
