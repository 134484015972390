import React from "react";
import SocialLink from "../atoms/SocialLink";
import { FaGithub, FaLinkedin, FaStackOverflow } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";

const SocialMenu = () => {
  return (
    <div className="hidden xl:flex fixed flex-col top-[35%] left-0">
      <ul>
        {/** Linkedin */}
        <SocialLink
          name="Linkedin"
          icon={<FaLinkedin size={30} />}
          color="bg-blue-600"
          link="https://www.linkedin.com/in/hakim-benmazouz-1651a5b2/"
        />

        {/** Github */}
        <SocialLink
          name="Github"
          icon={<FaGithub size={30} />}
          color="bg-[#333333]"
          link="https://github.com/HakimBe"
        />
        {/** Email */}
        <SocialLink
          name="Email"
          icon={<HiOutlineMail size={30} />}
          color="bg-[#6fc2b0]"
          link="mailto: benmazouz.hakim@gmail.com"
        />

        <SocialLink
          name="SO"
          icon={<FaStackOverflow size={30} />}
          color="bg-[#f58025]"
          link="https://stackoverflow.com/users/9808894/hakim-benmazouz"
        />
      </ul>
    </div>
  );
};

export default SocialMenu;
