import React from "react";
import { FaGithub, FaLinkedin, FaStackOverflow } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";

const Contact = () => {
  return (
    <div name="contact" className="w-full h-screen bg-[#0a192f] text-gray-300">
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="max-w-[1000px] w-full grid md:grid-cols-4 grid-cols-2 gap-4">
          <div className="sm:text-right pb-8 pl-4 flex  justify-center items-center">
            <a href="https://www.linkedin.com/in/hakim-benmazouz-1651a5b2/">
              <FaLinkedin size={100} />
            </a>
          </div>
          <div className="sm:text-right pb-8 pl-4 flex  justify-center items-center">
            <a href="https://github.com/HakimBe">
              <FaGithub size={100} />
            </a>
          </div>
          <div className="sm:text-right pb-8 pl-4 flex  justify-center items-center">
            <a href="https://stackoverflow.com/users/9808894/hakim-benmazouz">
              <FaStackOverflow size={100} />
            </a>
          </div>
          <div className="sm:text-right pb-8 pl-4 flex  justify-center items-center">
            <a href="mailto: benmazouz.hakim@gmail.com">
              <HiOutlineMail size={100} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
