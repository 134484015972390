import React, { useState } from "react";
import Menu from "../moleculs/Menu";
import SocialMenu from "../moleculs/SocialMenu";
import LogoSmall from "../moleculs/LogoSmall";
import { FaBars, FaTimes } from "react-icons/fa";
import MobileLink from "../atoms/MobileLink";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className="fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300">
      {/** Logo */}
      <LogoSmall />

      {/** Menu */}
      <Menu />

      {/** Mobile menu */}
      <div onClick={handleClick} className="md:hidden z-10">
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      <ul
        className={
          !nav
            ? "hidden"
            : "absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center"
        }
      >
        <MobileLink onClick={handleClick} to="/" label="Home" />
        <MobileLink onClick={handleClick} to="/about" label="About" />
        <MobileLink onClick={handleClick} to="/experience" label="Experience" />
        <MobileLink onClick={handleClick} to="/contact" label="Contact" />
      </ul>

      {/** Social icons */}
      <SocialMenu />
    </div>
  );
};

export default Navbar;
