import React from "react";

const SocialLink = (props) => {
  const classes =
    "w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 " +
    props.color;

  return (
    <li className={classes}>
      <a className="flex justify-between items-center w-full" href={props.link}>
        {props.name} {props.icon}
      </a>
    </li>
  );
};

export default SocialLink;
